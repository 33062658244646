import LogoTamama from "./assets/изображение-removebg-preview 1.png";
import FirstImg from "./assets/image 5.png";
import SecondImg from "./assets/image 12.png";
import ThirdImg from "./assets/image 2.png";
import FourthImg from "./assets/image 1.png";
import FifthImg from "./assets/image 4.png";
import SixthImg from "./assets/image 3.png";
import "./index.css";

function App() {
  return (
    <div className="bg-[#af0006] w-full h-[100vh] relative">
      {/* Заголовки */}
      <div className="z-[5] leading-[110px] pt-12 pl-32 laptop:pl-16 tablet:pl-8 phone:pl-4 phone:leading-[50px] tablet:leading-[80px]">
        <h1 className="text-[150px] font-normal text-white laptop:text-[120px] tablet:text-[80px] phone:text-[50px] phone:mt-[100px]">
          Скоро
        </h1>
        <h1 className="text-[85px] text-white laptop:text-[70px] tablet:text-[50px] phone:text-[40px] phone:ml-[90px]">
          Открытие!
        </h1>
      </div>
      {/* Логотип */}
      <img
        className="z-[5] w-[600px] ml-9 mt-[90px] laptop:w-[500px] tablet:w-[400px] phone:w-[300px] phone:ml-0 phone:mt-0 phone:absolute phone:top-1/2 phone:left-1/2 phone:transform phone:-translate-x-1/2 phone:-translate-y-1/2"
        src={LogoTamama}
        alt="logo"
      />
      {/* Текст */}
      <div className="z-[5] absolute bottom-[10%] left-[85px] laptop:left-[50px] tablet:left-[30px] tablet:bottom-[15%] phone:left-[16px] phone:bottom-[10%] text-white font-[50] text-2xl phone:text-lg">
        <p className="ml-1">Натуральная томатная паста с уникальным вкусом</p>
        <p>местных томатов и высокими стандартами качества.</p>
      </div>
      {/* Изображения */}
      <div>
        <img
          className="w-[500px] h-[300px] absolute top-0 right-[390px] laptop:w-[400px] tablet:w-[300px] tablet:h-[250px] phone:w-[200px] phone:h-[150px] phone:left-0"
          src={FirstImg}
          alt="first"
        />
        <img
          className="w-[450px] h-[340px] absolute top-[210px] right-[560px] laptop:w-[350px] tablet:w-[250px] tablet:h-[200px] tablet:top-[520px] phone:w-[200px] phone:h-[180px] phone:left-10"
          src={SecondImg}
          alt="second"
        />
        <img
          className="w-[350px] h-[350px] absolute top-[0px] right-[0px] laptop:w-[300px] tablet:w-[200px] tablet:h-[200px] phone:w-[200px] phone:h-[200px]"
          src={ThirdImg}
          alt="third"
        />
        <img
          className="w-[400px] h-[350px] absolute top-[220px] right-[130px] laptop:w-[300px] tablet:w-[250px] tablet:h-[200px] tablet:top-[350px] phone:w-[200px] phone:h-[180px] phone:top-[480px] phone:left-[200px]"
          src={FourthImg}
          alt="fourth"
        />
        <img
          className="w-[400px] h-[300px] absolute bottom-[0px] right-[350px] laptop:w-[300px] tablet:w-[250px] tablet:h-[200px] phone:w-[200px] phone:h-[170px] phone:left-0 phone:bottom-0"
          src={FifthImg}
          alt="fifth"
        />
        <img
          className="w-[300px] h-[300px] absolute bottom-[0px] right-[0px] laptop:w-[250px] tablet:w-[200px] tablet:h-[200px] phone:w-[200px] phone:h-[200px]"
          src={SixthImg}
          alt="sixth"
        />
      </div>
    </div>
  );
}

export default App;
